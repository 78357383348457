import React, { useState } from "react";
import { message } from 'antd';
import { ContactContainer } from "./styles";
import img6 from "../../../data/assets/contact.png";
import { API_ROOT } from "gatsby-env-variables";
const Contact = () =>
{

    const [ name, setName ] = useState( "" )
    const [ subject, setsubject ] = useState( "" )
    const [ email, setEmail ] = useState( "" )
    const [ query, setQuery ] = useState( "" )
    const [ errors, setErrors ] = useState( {});


    const validation = (key, value) =>
    {

        let errors = {};
        if ( !name )
        {
            errors.name = "**Name is Required"
            errors.color = "red"
        } if(key === "name" && !/^[a-zA-Z ]{3,} *$/.test( value ))
        {
            errors.name = "**Please enter valid name"
            errors.color = "red"
        } 
     
        if ( !subject)
        {
            errors.subject = "**Please enter your mobile number"
            errors.color = "red"
        }
        if(key === "subject"  && !/^[9876][0-9]{9}$/.test(value)){
            errors.subject = "**Please enter a valid number"
            errors.color = "red"
        }  if ( !email )

        {

            errors.email = "**Please enter your Email"

            errors.color = "red"

        } if (key === "email" &&  !/\S+@\S+\.\S+/.test( value ) )

        {

            errors.email = "**Email is invalid"

            errors.color = "red"

        }
        if ( !query )
        {
            
            errors.query = "**Please enter query"
            errors.color = "red"
        }
        if(key === "query" && !/^[a-zA-Z ]{10,} *$/.test( value ))
        {
            errors.query = "**Please enter query"
            errors.color = "red"
        } 
        return errors;
    }

    function onTextChange(name) {
        setName(name);
        setErrors(validation('name', name));
      }
      function onsubjectChange(phoneNumber) {
        setsubject(phoneNumber.substr(0,10));
        setErrors(validation('subject', phoneNumber));
      }

      function onEmailChange(email) {

        setEmail(email)
    
        setErrors(validation("email", email))
    
      }

      function onQueryChange(query) {

        setQuery(query)
    
        setErrors(validation("query", query))
    
      }
    /*=======================Api calling=========================*/
    async function contactSubmit ()
    {
        let item = { name, subject, email, query }

        let result = await fetch( API_ROOT+"contactUs", {
            method: "POST",
            body: JSON.stringify( item ),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        } )
        result = await result.json()
        if ( result.status === true )
        {
            success();
            clear();
            setErrors( true )
        } else
        {
            error();
        }
        console.log( result.status )
    }
    const signup2 = () =>
    {
        setErrors( validation() )
        warning();
    }
    const success = () =>
    {
        message.success( 'Registered' );
    };

    const error = () =>
    {
        message.error( 'Error' );
    };
    const warning = () =>
    {
        message.warning( 'Fill the required fields' );
    };

    const clear = () =>
    {
        setName( "" )
        setsubject( "" )
        setEmail( "" )
        setQuery( "" )
    }

    return (
        <ContactContainer>
            <div className="contact" id="contact">
                <h1>Get in Touch</h1>
                <div className="contact_contrainer">
                    <div className="contact_img_block">
                        <img src={ img6 } alt="img1" />
                    </div>
                    <div className="contact_info_block">
                        <div className="contact_info_top">
                            <div className="contact_name" style={ { position: `relative` } }>
                                { ( !name ) ?  ( <span style={ { color: ( errors.color ) } }>Your Name</span> ) : ( <span>Your Name</span> ) }

                                <input type="text" placeholder="Name" value={ name } 
                                 onChange={e => onTextChange(e.target.value)}
                                onBlur={e => onTextChange(e.target.value)} 
                                minLength={ 3 }
                                required=" true" />
                                <div class="redFont">{errors.name}</div>
                            </div>
                            <div className="contact_name"   style={ { position: `relative` } }>
                                { ( !subject ) ? ( <span style={ { color: ( errors.color ) } }>Mobile Number</span> ) : ( <span>Mobile Number</span> ) }
                                <input type="number" placeholder="Number" value={ subject }
                                onChange={e => onsubjectChange(e.target.value)}
                                minLength={10}
                                maxLength={10}
                                onBlur={e => onsubjectChange(e.target.value)} />
                                <div class="redFont">{errors.subject}</div>
                            </div>
                            
                        </div>
                        <div className="contact_mail" style={ { position: `relative` } }>
                            { ( !email || ( !/\S+@\S+\.\S+/.test( email ) ) ) ? ( <span style={ { color: ( errors.color) } }>Email ID</span> ) : ( <span>Email ID</span> ) }
                            <input type="mail" placeholder="chrisdo@gmail.com" type="text" value={ email }
                             onChange={e => onEmailChange(e.target.value)}
                             onBlur={e => onEmailChange(e.target.value)}   />
                            <div class="redFont">{errors.email}</div>
                        </div>
                        <div className="contact_message" style={ { position: `relative` } }>
                            { ( !query ) ?   ( <span style={ { color: ( errors.color ) } }>Message</span> ) : ( <span>Message</span> ) }
                           
                            <textarea type="mail" placeholder="Please Type Your Message" type="text" value={ query }  onChange={e => onQueryChange(e.target.value)}
                           onBlur={e => onQueryChange(e.target.value)} />
                            <div class="redFont">{errors.query}</div>
                        </div>
                        <div className="button">
                            { ( !name || name.length < 3 || !subject || !/^[9876][0-9]{9}$/.test(subject) || !email || !/\S+@\S+\.\S+/.test( email ) || !query || query.length < 10 ) ? (
                                <button onClick={ signup2 } style={ { background: `gray` } }>SEND MESSAGE</button>
                            ) : (
                                <>
                                    <button onClick={ contactSubmit }>SEND MESSAGE</button>
                                </>
                            ) }

                        </div>
                    </div>
                </div>

            </div>
        </ContactContainer>


    );
};
export default Contact;